@import url('https://fonts.googleapis.com/css2?family=Anta&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');


body {
  margin: 0;
  user-select: none;
}
* {
  font-family: "Roboto", sans-serif!important;
  font-style: normal;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
img {
  width: 100%;
}
a {
  text-decoration: none;
}
.font-anta {
  font-family: "Anta", sans-serif!important;
}
.content {
  margin-left: 256px;
  padding-top: 10px;
}
@media(max-width: 768px){
  .content {
    margin-left: 0;
  }
}

.text3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.text3::after {
  content: '...';
  display: block;
}